<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      fill-rule="evenodd"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M23.25 7.311l-10.72 10.71 -.01 0c-.3.29-.77.29-1.06 0 -.01-.01-.01-.01-.01-.01L.73 7.29"
    />
  </svg>
</template>
